<template>
  <div class="w1092">
    <div class="moneyreadWrap">
      <div class="boardName">
        <h2><span><img src="@/assets/img/menu/menu_cash_off_w.svg"></span>보유머니 입금<em>Deposit</em></h2>
      </div>
      <div class="moneyread">
        <h3><img src="@/assets/img/icon/icon_info.svg" /></h3>
        <ul>
          <li><!--img src="@/assets/img/icon_check.png" /-->1. {{$t('front.cash.moneyCheckListA')}}</li>
          <li><!--img src="@/assets/img/icon_check.png" /-->2. {{$t('front.cash.moneyCheckListB')}}</li>
        </ul>
        <ul>
          <li><!--img src="@/assets/img/icon_check.png" /-->3. {{$t('front.cash.moneyCheckListC')}}</li>
          <li><!--img src="@/assets/img/icon_check.png" /-->4. {{$t('front.cash.moneyCheckListD')}}</li>
        </ul>
      </div>
      <div class="moneyinfoappli">
        <div class="moneyinfo">
          <h3 class="applih"><img src="@/assets/img/icon/icon_check.svg">{{$t('front.cash.moneyCheckListE')}}</h3>
          <p class="applip">{{$t('front.cash.moneyCheckListF')}}</p>
          <a class="applibtn" @click="getAcc">{{$t('front.cash.moneyCheckListG')}}</a>

          <ul class="applistep">
            <li><em>1</em>{{$t('front.cash.we')}} <strong>{{$t('front.cash.moneyCheckListG')}}</strong>{{$t('front.cash.moneyCheckListH')}}</li>
            <li><em>2</em>{{$t('front.cash.moneyCheckListI')}}</li>
            <li><em>3</em><strong>{{$t('front.cash.moneyCheckListJ')}}</strong>{{$t('front.cash.moneyCheckListK')}}<strong>{{$t('front.cash.moneyCheckListL')}}</strong>{{$t('front.cash.moneyCheckListM')}}</li>
          </ul>
        </div>

        <div class="appliinputwrap">
          <ul class="appliinput">
            <li>{{$t('front.cash.moneyHave')}}</li>
            <li><span class="b w260"><img src="@/assets/img/icon/icon_cash_y.svg">{{thousand(userData.cashAmt)}}</span> {{$t('front.common.money')}}</li>
          </ul>
          <ul class="appliinput">
            <li>{{$t('front.cash.moneyInput')}}</li>
            <li><input type="text" pattern="[0-9.,]+" class="numb w260" :placeholder="$t('front.cash.inputNumber')" v-model="setCurrentChargeMoney" :readonly="true" @focus="onFocus">{{$t('front.common.money')}}</li>
          </ul>
          <ul class="moneybtnwrap">
            <li class="one"><a @click="setMoney(10000)">1{{$t('front.cash.manwon')}}</a></li>
            <li class="one"><a @click="setMoney(30000)">3{{$t('front.cash.manwon')}}</a></li>
            <li class="one"><a @click="setMoney(50000)">5{{$t('front.cash.manwon')}}</a></li>
            <li class="two"><a @click="setMoney(100000)">10{{$t('front.cash.manwon')}}</a></li>
            <li class="two"><a @click="setMoney(300000)">30{{$t('front.cash.manwon')}}</a></li>
            <li class="two"><a @click="setMoney(500000)">50{{$t('front.cash.manwon')}}</a></li>
            <li class="two"><a @click="setMoney(1000000)">100{{$t('front.cash.manwon')}}</a></li>
            <li class="thr"><a @click="setMoney(0)">{{$t('front.cash.correct')}}</a></li>
          </ul>
          <!--ul class="appliinput">
            <li>보너스 금액</li>
            <li><span class="pp b w110">{{thousand(bonus)}}</span> 원</li>
          </ul>
          <ul class="appliinput">
            <li>보너스 혜택</li>
            <li class="moneybtnwrap">
              <a class="fiv" :class="{ on: isApplyBonus }" @click="isApplyBonus=!isApplyBonus" >보너스 받기</a>
              <a class="fiv" :class="{ on: !isApplyBonus }" @click="isApplyBonus=!isApplyBonus" >보너스 받지않기</a>
            </li>
          </ul-->
          <div class="applibtns">
            <a @click="onSubmit('pc')">{{$t('front.cash.moneyCheckListL')}}</a>
          </div>
        </div>
      </div>
    </div>

    <div class="board moneyreadWrap">
      <div class="boardName">
        <h2><span><img src="@/assets/img/menu/menu_cash_off_w.svg"></span>입금내역</h2>
      </div>
      <ul class="boardw first">
        <li class="">{{$t('front.board.applyDay')}}</li>
        <li class="">{{$t('front.board.processMoney')}}</li>
        <li class="">{{$t('front.board.processBonus')}}</li>
        <li class="">{{$t('front.board.processDay')}}</li>
        <li class="">{{$t('front.board.processState')}}</li>
      </ul>
      <template v-if="cashList.length > 0">
        <ul class="boardw" v-for="item in cashList" v-bind:key="item.cashIdx">
          <li class="">{{dateFormat(item.regDate)}}</li>
          <li class="">{{thousand(item.cashAmt)}}</li>
          <li class="">0</li>
          <li class="">{{dateFormat(item.updDate)}}</li>
          <li class=""><span :class="{'bl' : item.cashStatus !== '-1'}">{{computedCashStatus(item.cashStatus).text}}</span></li>
        </ul>
      </template>
      <template v-else>
        <ul class="boardw">
          <li>{{$t('front.common.notFoundList')}}</li>
        </ul>
      </template>
    </div>
    <div class="boardpage mt20 mb20">
      <pagination :pageNum="pageInfo.page"
                  :pageSize="pageInfo.count_per_list"
                  :totalCount="pageInfo.tatal_list_count"
                  @goToPage="onCashListSearch" />
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/ui/Pagination'
import { getDateStr, getSubDaysDate, thousand } from '@/libs/utils'
import { cashDelete, cashIn, getAccount } from '@/api/cash'

import store from '@/store'
export default {
  name: 'Charge',
  components: {
    Pagination
  },
  watch: {
    type: function () {
      // if (this.type === 'list') {
      this.onCashListSearch()
      // }
    },
    setCurrentChargeMoneyStr () {
      // this.setCurrentChargeMoneyStr = thousand(this.setCurrentChargeMoneyStr)
    },
    setCurrentChargeMoney () {
      // this.setCurrentChargeMoney = thousand(this.setCurrentChargeMoney)
      const parts = this.setCurrentChargeMoney.split('.')
      const v = parts[0].replace(/\D/g, '')
      const dec = parts[1]
      const calcNum = Number((dec !== undefined ? v + '.' + dec : v))
      // use this for numeric calculations
      console.log('number for calculations: ', calcNum)
      let n = new Intl.NumberFormat('en-EN').format(v)
      n = dec !== undefined ? n + '.' + dec : n
      this.setCurrentChargeMoney = n
    }
  },
  created () {
    this.onCashListSearch()
  },
  data () {
    return {
      type: 'apply',
      setCurrentChargeMoney: '',
      isApplyBonus: false,
      bonus: 0,
      cashList: [],
      allChecked: false,
      cashType: 'in',
      searchDate: {
        startDate: getDateStr(getSubDaysDate(new Date(), 2), 'yyyy-MM-dd 00:00:00'),
        endDate: getDateStr(new Date(), 'yyyy-MM-dd 23:59:59')
      },
      isProcessing: false
    }
  },
  methods: {
    getAcc () {
      getAccount({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.onCheck('front.cash.moneyCheckListF')
        }
      })
    },
    async onRemoveMsg () {
      let checkedCount = 0
      this.cashList.forEach(item => {
        if (item.checked) {
          checkedCount++
        }
      })
      if (checkedCount <= 0) {
        this.onCheck('front.cash.noSelectMessage')
        return false
      }
      const confirm = await this.onConfirm('front.cash.confirmDeleteMessage')

      if (confirm) {
        for (let i = 0, iLen = this.cashList.length; i < iLen; i++) {
          const item = this.cashList[i]
          if (item.checked) {
            cashDelete(item).then(response => {
            })
          }
        }
        this.onCheck('front.cash.completeDeleteMessage')
        this.onCashListSearch()
      }
    },
    setMoney (value) {
      let money = Number(this.setCurrentChargeMoney.replace(/,/g, ''))
      if (value) {
        money += value
      } else {
        money = value
      }
      this.setCurrentChargeMoney = thousand(money)
      // this.setCurrentChargeMoney = thousand(this.setCurrentChargeMoney)
    },
    async onSubmit (device = 'mobile') {
      if (this.setCurrentChargeMoney && this.setCurrentChargeMoney !== '0') {
        const confirm = await this.onConfirm('front.cash.confirmCharge')

        if (confirm) {
          if (!this.isProcessing) {
            this.isProcessing = true
            this.emitter.emit('Loading', true)

            const params = {
              memId: this.userData.memId,
              cashType: 1, // in,
              cashStatus: 'in',
              cashAmt: this.setCurrentChargeMoney.replace(/,/g, '')
            }

            cashIn(params).then(response => {
              store.dispatch('storeUserData').then(userData => {

              }).then(async () => {
                this.emitter.emit('Loading', false)
                const confirm = await this.onCheck('front.cash.completeCharge')
                if (confirm) {
                  if (device === 'mobile') {
                    this.replacePageByName('my')
                  } else {
                    location.reload()
                  }
                }
              })
            }).catch(err => {
              this.emitter.emit('Loading', false)
              console.error(err)
            })
          }
        }
      } else {
        this.onAlert('warningart', 'front.cash.emptyPrice')
      }
    }
  }
}
</script>
<style scoped>
.moneyreadWrap {padding: 13px 20px;border-radius: 12px;background: #fff;font-weight: normal;text-align: left;}
.moneyread {padding: 13px 20px;border-radius: 12px;border: 1px solid #EAEDFF;font-weight: normal;display: flex; align-items: center; justify-content: space-between;}
.moneyread ul {width: 48%;}
.moneyread h3 { padding: 8px; background:#E8EBFA; border-radius: 7px; }
.moneyread h3 img {height: 15px;vertical-align: middle;}
.moneyread ul li {color: #000;line-height: 1.5em;font-size: 13px;margin-top: 10px;}
.moneyread ul li img {margin: 0 11px 0 9px;}

.moneyinfoappli {background: none;border-radius: 12px;padding: 25px 30px 27px;margin: 28px auto 75px; width:650px; }
.moneyinfo {border-radius: 12px;border: 1px solid #EAEDFF;background: #F4F7FF ;padding: 20px;line-height: 1.5em;}
.applih {color: #464C5A;font-size: 16px;font-weight: bold; display: flex; align-items: center; gap: 10px;}
.applip {color: #141414;padding: 10px 0 15px;font-size: 14px;}
.applibtn {width: 109px;height: 34px;border-radius: 5px;background: #EFF8E3; border: 1px solid #8FD03F;font-size: 14px;color: #8FD03F;display: flex;justify-content: center;align-items: center;}
.applistep {color: #000;letter-spacing: -1px; margin-top: 10px}
.applistep li {counter-increment: li;margin-top: 5px;font-size: 14px;}
.applistep li em {margin-right: 5px;padding: 0px 8px;font-size: 14px;color: #141414;}
.applistep strong {color: #141414;}

.appliinputwrap {padding-top: 48px;}
.appliinputwrap>ul {margin-top: 50px;}
.appliinputwrap>ul:first-child {margin-top: 0;}
.appliinput {display: flex; flex-direction: column; gap: 36px;font-size: 16px;color: #141414;}
.appliinput li:first-child {width: 122px;font-weight: bold;color: #141414;position: relative;}
.appliinput li:nth-child(2) {background: #F4F7FF; display: flex; padding-left: 17px; border-radius: 10px; box-sizing: border-box; height: 40px; line-height: 40px;}
/* .appliinput li:first-child::after {content: '';width: 2px;height: 19px;background: #333;position: absolute;right: 0;} */
.appliinput li {width: 350px;}
.appliinput li span {display: flex; align-items: center;}
.appliinput li span img { margin-right: 15px; }
.appliselect {border-radius: 5px;border: 1px solid #4575b4;width: 133px;height: 28px;}
.numb {padding: 0;border: 0;background: none;width: 100%; color: #141414;font-size: 16px;}
.moneybtnwrap {display: flex;gap: 3px;margin-top: 26px !important;}
.moneybtnwrap a {display: inline-block;color: #fff;width: 78px;border-radius: 6px;text-align: center; height: 40px; line-height: 40px; box-sizing: border-box; font-size: 14px;}
.moneybtnwrap .one a {background: linear-gradient(180deg, #1A65F4 0%, #5D98FF 100%);}
.moneybtnwrap .two a {background: linear-gradient(180deg, #6722F1 0%, #8E3AEA 100%);}
.moneybtnwrap .thr a {background: linear-gradient(180deg, #DE5251 0%, #FA8A74 100%);}
.moneybtnwrap .four a {background: #777 ;}
.moneybtnwrap .fiv {padding: 0;width: 130px;height: 34px;display: flex;justify-content: center;align-items: center;font-size: 14px;background: #fff;border: 1px solid #1c594e;color: #1c594e;}
.moneybtnwrap .fiv.on {background: #e1423c;border: 1px solid #4575b4;color: #fff;}
.applibtns {margin-top: 63px;display: flex;justify-content: center;gap: 78px;font-size: 16px;}
.applibtns a {background:  linear-gradient(180deg, #73AA2C 0%, #8FD03F 100%);border-radius: 10px;width: 320px;height: 40px;color: #fff;display: flex;align-items: center;justify-content: center;}
.board {margin-top: 30px;}
.boardw.first {
  background: #fff;
}
</style>

<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
